import React, { useEffect, useState } from 'react';
import axios from "axios";
import { getAuth } from "firebase/auth";

// Mock data for initial folders and files
let initialData = {
  root: {
    folders: [],
    files: [],
  },
};

async function getDocumentList() {
    const auth = getAuth();
    const token = await auth.currentUser.getIdToken();
    try {
        const response = await axios.get('https://ltgi076zwj.execute-api.us-east-1.amazonaws.com/default/manageFiles', {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching documents: ', error);
    }
}

// Function to create a new folder
async function createFolder(folderName) {
    const auth = getAuth();
    const token = await auth.currentUser.getIdToken();
    try {
        const response = await axios.post(
            'https://ltgi076zwj.execute-api.us-east-1.amazonaws.com/default/manageFiles',
            {
                folder: folderName
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error creating folder: ', error);
    }
}

// Function to upload a file
async function uploadFile(folderName, fileName, fileContent) {
    const auth = getAuth();
    const token = await auth.currentUser.getIdToken();
    try {
        const response = await axios.post(
            'https://ltgi076zwj.execute-api.us-east-1.amazonaws.com/default/manageFiles',
            {
                folder: folderName,
                fileName: fileName,
                fileContent: fileContent,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error uploading file: ', error);
  }
}

// Function to delete a file or folder
async function deleteItem(folderName, fileName = null) {
    const auth = getAuth();
    const token = await auth.currentUser.getIdToken();
    try {
        const response = await axios.delete(
            'https://ltgi076zwj.execute-api.us-east-1.amazonaws.com/default/manageFiles',
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                data: {
                    folder: folderName,
                    fileName: fileName || undefined, // Send fileName if present, otherwise just delete the folder
                }
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error deleting item: ', error);
    }
}

export function DocumentDrive() {
  const [data, setData] = useState(initialData);
  const [folderPath, setFolderPath] = useState(['root']); // Track the folder path
  const [newFolderName, setNewFolderName] = useState('');
  const [fileInput, setFileInput] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]); // Track selected items
  const [showActions, setShowActions] = useState(false); // Control actions dropdown visibility

  useEffect(() => {
    getDocumentList().then((data) => {
        setData(data);
    });
  }, []);
  
  const currentFolder = folderPath[folderPath.length - 1];

  // Handle folder creation
  const handleCreateFolder = async () => {
    if (newFolderName.trim()) {
      // Construct the full folder path based on the current folder
      const fullFolderPath = folderPath.join('/') === 'root' ? newFolderName : `${folderPath.join('/')}/${newFolderName}`.replace('root/', '');
  
      // Call API to create folder in Lambda with full folder path
      await createFolder(fullFolderPath + '/');
  
      // Update the UI
      const updatedFolders = [...data[currentFolder].folders, newFolderName];
      setData({
        ...data,
        [currentFolder]: {
          ...data[currentFolder],
          folders: updatedFolders,
        },
        [newFolderName]: { folders: [], files: [] }, // Initialize the new folder
      });
      setNewFolderName(''); // Reset folder name input
    }
  };

  // Handle file upload
  const handleFileUpload = async (event) => {
    const files = event.target.files;
    if (files.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = async function () {
        const base64Content = reader.result.split(',')[1]; // Base64 content of the file
        const fullFolderPath = folderPath.join('/') === 'root' ? 'root' : folderPath.join('/').replace('root/', '');; 
        await uploadFile(fullFolderPath, file.name, base64Content);

        const updatedFiles = [...data[currentFolder].files, { name: file.name }];
        setData({
          ...data,
          [currentFolder]: {
            ...data[currentFolder],
            files: updatedFiles,
          },
        });
        setFileInput(null); // Reset the file input
      };
      reader.readAsDataURL(file);
    }
  };

  const handleOpenFolder = (folderName) => {
    setFolderPath([...folderPath, folderName]);
  };

  // Handle going back to the previous folder
  const handleGoBack = () => {
    if (folderPath.length > 1) {
      setFolderPath(folderPath.slice(0, folderPath.length - 1));
    }
  };

  // Handle right-click to select items
  const handleSelectItem = (event, item) => {
    event.preventDefault(); // Prevent default right-click behavior (context menu)
    
    if (event.ctrlKey || event.metaKey) {
      if (selectedItems.includes(item)) {
        setSelectedItems(selectedItems.filter((i) => i !== item)); // Deselect
      } else {
        setSelectedItems([...selectedItems, item]); // Select multiple
      }
    } else {
      // Single selection
      setSelectedItems([item]);
    }
  };

  // Handle delete action
  const handleDelete = async () => {
    for (const item of selectedItems) {
      if (data[currentFolder].folders.includes(item)) {
        // If it's a folder, delete the folder and its contents
        await deleteItem(item);
      } else if (data[currentFolder].files.some(file => file.name === item)) {
        // If it's a file, delete the specific file
        await deleteItem(currentFolder, item);
      }
    }

    // Update the UI after deletion
    const updatedFiles = data[currentFolder].files.filter((file) => !selectedItems.includes(file.name));
    const updatedFolders = data[currentFolder].folders.filter((folder) => !selectedItems.includes(folder));

    setData({
      ...data,
      [currentFolder]: {
        ...data[currentFolder],
        files: updatedFiles,
        folders: updatedFolders,
      },
    });
    setSelectedItems([]); // Reset selection after action
    setShowActions(false); // Close actions dropdown after the action
  };

  return (
    <div className="min-h-screen bg-gray-100 p-4 sm:p-8">
      <div className="max-w-7xl mx-auto bg-white shadow-lg rounded-lg p-4 sm:p-6">
        <h1 className="text-xl sm:text-2xl font-bold mb-4 text-gray-800">Document Drive</h1>
  
        {/* Breadcrumb */}
        <div className="text-xs sm:text-sm text-gray-600 mb-4 overflow-x-auto whitespace-nowrap">
          {folderPath.map((folder, index) => (
            <span key={index}>
              <button
                onClick={() => setFolderPath(folderPath.slice(0, index + 1))}
                className="text-blue-600 hover:underline"
              >
                {folder}
              </button>
              {index < folderPath.length - 1 && ' / '}
            </span>
          ))}
        </div>
  
        {/* Back Button and Create Folder/Upload File Buttons */}
        <div className="flex flex-wrap items-center justify-between space-y-2 sm:space-y-0 sm:space-x-4">
          {/* Back Button */}
          <button
            onClick={handleGoBack}
            disabled={folderPath.length === 1}
            className={`px-2 py-1 sm:px-4 sm:py-2 rounded-lg transition flex items-center ${
              folderPath.length === 1
                ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                : 'bg-gray-200 text-gray hover:bg-gray-600'
            }`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="w-4 h-4 sm:w-5 sm:h-5"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
          </button>
  
          {/* Create Folder, Upload File, and Action Dropdown */}
          <div className="flex-grow flex flex-wrap space-y-2 sm:space-y-0 sm:space-x-4">
            {/* Create Folder Section */}
            <div className="flex space-x-2">
              <input
                type="text"
                value={newFolderName}
                onChange={(e) => setNewFolderName(e.target.value)}
                placeholder="Folder Name"
                className="px-2 sm:px-4 py-1 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <button
                onClick={handleCreateFolder}
                className="bg-indigo-500 text-white px-3 sm:px-4 py-1 sm:py-2 rounded-lg hover:bg-indigo-600 transition"
              >
                Create Folder
              </button>
            </div>
  
            {/* Custom File Upload Button */}
            <div className="relative">
              <input
                type="file"
                multiple
                onChange={handleFileUpload}
                className="absolute inset-0 opacity-0 w-full h-full cursor-pointer"
                ref={(input) => setFileInput(input)}
              />
              <button
                className="bg-sky-500 text-white px-3 sm:px-4 py-1 sm:py-2 rounded-lg hover:bg-slate-700 transition"
                onClick={() => fileInput.click()}
              >
                Upload Files
              </button>
            </div>
  
            {/* Action Dropdown */}
            <div className="relative">
              <button
                onClick={() => setShowActions(!showActions)}
                disabled={selectedItems.length === 0}
                className={`bg-gray-500 text-white px-3 sm:px-4 py-1 sm:py-2 rounded-lg hover:bg-gray-600 transition ${
                  selectedItems.length === 0 ? 'cursor-not-allowed opacity-50' : ''
                }`}
              >
                Actions
              </button>
              {showActions && selectedItems.length > 0 && (
                <div className="absolute right-0 mt-2 bg-white shadow-lg border border-gray-200 rounded-lg z-10">
                  <button
                    onClick={handleDelete}
                    className="block px-4 py-2 text-red-500 hover:bg-gray-100 w-full text-left"
                  >
                    Delete
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
  
        {/* List View */}
        <div className="flex flex-col space-y-1 mt-6 bg-gray-100 rounded-lg text-gray-500">
          {data[currentFolder].folders.length === 0 && data[currentFolder].files.length === 0 ? (
            <p className="text-gray-500 text-center mt-20 mb-20">Folder empty</p>
          ) : (
            <>
              {data[currentFolder].folders.map((folder, index) => (
                <div
                  key={`folder-${index}`}
                  className={`flex items-center justify-between px-4 py-1 rounded-lg cursor-pointer text-md ${
                    index % 2 === 0 ? 'bg-gray-100 hover:bg-gray-200' : 'bg-gray-200 hover:bg-gray-300'
                  } ${selectedItems.includes(folder) ? 'ring-2 ring-blue-500' : ''}`}
                  onClick={() => handleOpenFolder(folder)}
                  onContextMenu={(event) => handleSelectItem(event, folder)}
                >
                  <div className="flex items-center space-x-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M3 7h4l2-2h8a2 2 0 012 2v10a2 2 0 01-2 2H5a2 2 0 01-2-2V9a2 2 0 012-2z"
                      />
                    </svg>
                    <span>{folder}</span>
                  </div>
                </div>
              ))}
              {data[currentFolder].files.map((file, index) => (
                <div
                  key={`file-${index}`}
                  className={`flex items-center justify-between px-4 py-1 rounded-lg cursor-pointer text-sm ${
                    index % 2 === 0 ? 'bg-gray-100 hover:bg-gray-200' : 'bg-gray-200 hover:bg-gray-300'
                  } ${selectedItems.includes(file.name) ? 'ring-2 ring-blue-500' : ''}`}
                  onContextMenu={(event) => handleSelectItem(event, file.name)}
                >
                  <div className="flex items-center space-x-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 2h7l5 5v13a2 2 0 01-2 2H6a2 2 0 01-2-2V4a2 2 0 012-2z"
                      />
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 2v6h6" />
                    </svg>
                    <span>{file.name}</span>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
  
}
